*,
body,
html {
    font-family: 'Roboto', sans-serif !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

button {
    cursor: pointer !important;
}

// start: auth-page
.auth-page {
    padding: 0 0 40px;

    .auth-logo {
        display: flex;
        justify-content: center;

        @media (max-width: 575px) {
            img {
                max-width: 160px;
            }
        }
    }

    .auth-content-box {
        border: 1px solid #CAC4D0;
        border-radius: 12px;
        padding: 32px 16px;

        .MuiTypography-h3 {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.5px;
            color: #1C1B1F;

            &~.MuiTypography-h6 {
                margin-top: 15px;
            }
        }

        .MuiTypography-h6 {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.25px;
            color: #49454F;
        }

        .auth-form {
            margin-top: 22px;

            .form-submit-group-s1 {
                display: flex;
                flex-direction: column;
                row-gap: 16px;

                &.type2 {
                    row-gap: 2px;
                }

                &+.form-submit-group-s1 {
                    margin-top: 24px;
                }

                .auth-submit-btn {
                    height: 40px;
                    text-transform: capitalize;
                    column-gap: 8px;

                    &.MuiButton-outlined {
                        background: #E8EBEC;
                    }
                }

                .auth-submit-text-btn {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    letter-spacing: 0.25px;
                    color: #0583F2;
                    text-decoration: none;
                    cursor: pointer;

                    &.type2 {
                        color: #49454F;
                        cursor: auto;
                    }
                }
            }

        }
    }
}

.button-radius-60 {
    border-radius: 60px !important;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.react-datepicker__input-container {
    background: none;
    margin: 0;
    display: block;
    min-width: 0;
    width: 100%;
    height: 56px;

    input {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0px 14px;
        background: none;
        border-radius: 4px;
        border: 1px solid #c4c4c4;
        outline: none;

        &:focus {
            outline: none;
            border-color: #0583F2;
        }
    }

}

.form-group-s1 {
    margin-bottom: 20px;
    width: 100%;
    position: relative;

    .form-control-with-right-icon {
        position: relative;

        input {
            padding-right: 40px !important;
        }

        .right-icon {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            display: flex;
        }

        &.verified {
            input {
                padding-right: 90px !important;

                @media (max-width: 575px) {
                    padding-right: 80px !important;

                }
            }

            .right-icon {
                display: flex;
                column-gap: 5px;

                span {
                    font-style: italic;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 23px;
                    letter-spacing: 0.5px;
                    color: #219653;

                    @media (max-width: 575px) {
                        font-size: 13px;
                        line-height: 20px;

                        img {
                            width: 11px;
                        }
                    }
                }
            }

            .wrong-icon {
                display: flex;
                column-gap: 5px;

                span {
                    font-style: italic;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 23px;
                    letter-spacing: 0.5px;
                    color: #a42222;

                    @media (max-width: 575px) {
                        font-size: 13px;
                        line-height: 20px;

                        img {
                            width: 11px;
                        }
                    }
                }
            }
        }
    }

    .add-tag-btn {
        position: absolute;
        height: 45px;
        max-width: 50px;
        min-width: 50px;
        right: 5px;
        top: 5px;
        background: #cfe2f3;
    }

    .MuiFormControl-root {
        margin: 0;

        .MuiInputLabel-shrink {}

        .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
            color: #0583F2;
        }

        // .Mui-focused {
        //     background-color: #E8EBEC;
        // }
    }
}

.tag-input {
    flex-wrap: wrap !important;
    flex-direction: row !important;

    .show-added-tags {
        width: auto;
        min-width: initial;
        max-width: max-content !important;
        height: auto;
        padding: 10px 10px 10px 15px !important;
        border-radius: 5px !important;

        button {
            height: auto !important;
            min-width: 20px;
            min-height: 20px;
            line-height: normal;
            padding: 0;
            display: inline-block;
            position: relative;
            margin: 0 0 0 10px !important;

            span {
                position: relative;
                width: auto;
                height: auto;
                display: inline-block;
                padding: 0 !important;
                display: none;
            }
        }
    }
}

// end: auth-page

// start: dashbaord theme customize
.css-t29xbm-MuiPaper-root-MuiAppBar-root {
    margin-left: 72px !important;
    width: calc(100% - 72px) !important;

    @media (max-width: 1024.98px) {
        margin-left: 0px !important;
        width: calc(100% - 0px) !important;
    }
}

.main-sitebar {
    @media (max-width: 1024.98px) {
        visibility: hidden;

        .mainsite-bar-drwaer-open,
        .MuiDrawer-docked {
            position: fixed;
            top: 0;
            height: 100%;
            left: 0;
            width: 240px;
            z-index: 999;
            transition: .5s;
            transform: translate(-100%);
            visibility: visible;

            .MuiDrawer-paper {
                width: 100% !important;
            }
        }

        .mainsite-bar-drwaer-open {
            transform: translate(0) !important;
        }
    }

    // .mainsite-bar-drwaer-open {

    .sidebar-logo {
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            display: block;
            margin: 0 auto;
            width: 50px;
        }
    }

    .navbar-lists {
        row-gap: 10px;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 59px);

        .navbar-list-item {
            flex-grow: 0 !important;

            .MuiListItemIcon-root {
                min-width: 38px;

                img {
                    width: 24px;
                    height: auto;
                }
            }

            .MuiListItemText-root {
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.5px;
                color: #1E1E1E;

            }

            // &.active{
            //     .css-tlelie-MuiListItemText-root {
            //         color: #0583F2;
            //     }
            // }
        }
    }

    // }

    //  alfter collapse
    @media (min-width: 1025px) {
        .mainsite-bar-drwaer-close .MuiDrawer-paper {
            .navbar-lists {
                padding-top: 5px;
                row-gap: 20px;

                .navbar-list-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    padding: 8px 8px;

                    &.active {
                        .css-tlelie-MuiListItemText-root {
                            display: block !important;
                        }

                        .MuiListItemText-root {
                            display: block !important;
                        }

                        padding: 8px 8px 0;

                        .css-cveggr-MuiListItemIcon-root {
                            background: #0583F2 !important;
                            height: 25px;
                            border-radius: 60px;

                            img {
                                filter: brightness(0) invert(1);
                                width: 18px;
                            }
                        }

                        .MuiListItemIcon-root {
                            background: #0583F2 !important;
                            height: 25px;
                            border-radius: 60px;

                            img {
                                filter: brightness(0) invert(1);
                                width: 18px;
                            }
                        }
                    }

                    .MuiListItemIcon-root {
                        min-width: unset !important;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {}
                    }

                    .MuiListItemText-root {
                        display: none;
                    }
                }
            }
        }
    }
}

.dashboard-header-main {

    header,
    .css-xhk3oc-MuiPaper-root-MuiAppBar-root {
        display: flex;
        justify-content: space-between;
        flex-direction: revert;
        align-items: center;
        background-color: #fff;
        box-shadow: 3px -1px 5px grey;

        @media (max-width: 1024.98px) {
            margin-left: 0px !important;
            width: calc(100% - 0px) !important;
        }

        .left-toolbar {
            min-height: 56px !important;
            flex: 0 0 auto;

            .toggle-btn {}
        }

        .right-toolbar {
            min-height: 56px !important;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            column-gap: 15px;
            flex: 1 1 auto;

            @media (max-width: 480px) {
                column-gap: 10px;
                padding-left: 0;
            }

            .search-box {
                width: 100%;
                max-width: 500px;
                height: 42px;
                margin: 3px 15px 3px 0px;
                // width: 210px;
                position: relative;
                // border-radius: 30px;
                // overflow: hidden;

                @media (max-width: 767px) {
                    max-width: 260px;
                }

                @media (max-width: 575px) {
                    margin-right: 12px;
                }

                @media (max-width: 400px) {
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background-color: #F6F6F6;
                    background-image: url(../images/toolbar-search.svg);
                    background-repeat: no-repeat;
                    background-size: 24px;
                    background-position: center;
                }

                .search-close {
                    width: 30px;
                    height: 30px;
                    min-width: 30px;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 6px;
                    cursor: pointer;
                    color: #000;
                    display: flex;
                    align-items: center;
                    z-index: 1202;

                    @media (max-width: 575px) {
                        right: 5px;

                        svg {
                            width: 13px;
                            height: auto;
                        }
                    }
                }

                .search-input {
                    background-image: url(../images/toolbar-search.svg);
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 100%;
                    background-color: #F6F6F6;
                    border: 0;
                    background-position: center right 10px;
                    outline: none !important;
                    padding: 0 40px 0 12px;

                    position: relative;

                    &.active {
                        background-image: unset;
                        z-index: 1202;
                    }

                    @media (max-width: 480px) {
                        background-size: 15px;
                        background-position: center right 6px;
                        padding: 0 25px 0 8px;
                    }

                    @media (max-width: 400px) {
                        display: none;
                    }

                    &.search-input-mob {
                        display: block;
                        position: fixed;
                        top: 55px;
                        left: 0px;
                        right: 0px;
                        margin: 0px auto;
                        max-width: 75%;
                        height: 40px;
                        background-image: none;
                        z-index: 99;
                    }
                }

                .search-results-dropdown {
                    position: absolute;
                    top: calc(100% + 1px);
                    right: 0;
                    // width: 405px;
                    background: #fff;
                    box-shadow: 0 9px 15px #00000014;
                    border-radius: 5px;
                    padding: 12px;
                    display: none;
                    z-index: 1202;
                    width: 100%;
                    min-height: 25rem !important;

                    &.show {
                        display: block;
                    }

                    @media (max-width: 767px) {
                        position: fixed;
                        top: 57px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: calc(100% - 10px);
                    }

                    h3 {
                        font-size: 14px;
                        color: #000;
                        margin: 0;
                    }

                    .explore-categories-list {
                        display: flex;
                        row-gap: 8px;
                        column-gap: 8px;
                        margin: 0 0;
                        overflow-x: auto;
                        overflow-y: hidden;
                        scrollbar-color: #0583F2 gray;
                        scrollbar-width: thin;
                        padding: 10px 0 4px;
                        list-style: none;

                        &::-webkit-scrollbar {
                            width: 100%;
                            background: #dcdcdc;
                            height: 5px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: #0583F2;
                            border-radius: 4px;
                        }

                        li {
                            margin: 0;
                            padding: 0;
                            flex: 0 0 auto;
                        }

                        .exp-cate-btn {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                            background: #f0f8ff;
                            color: #0583F2;
                            border: 1px solid #0583F2;
                            border-radius: 60px;
                            font-weight: 500;
                            font-size: 12px;
                            height: 34px;
                            line-height: 17px;
                            padding: 0px 15px;
                            white-space: nowrap;
                            margin: 0;


                        }
                    }

                    .search-results {

                        //gird-view-results
                        .gird-view-results {
                            ul {
                                cursor: pointer;
                                display: flex;
                                overflow-y: hidden;
                                overflow-x: auto;
                                scrollbar-color: #0583F2 gray;
                                scrollbar-width: thin;
                                padding: 10px 0 4px;
                                list-style: none;
                                column-gap: 6px;
                                row-gap: 6px;
                                margin: 0;

                                &::-webkit-scrollbar {
                                    width: 100%;
                                    background: #dcdcdc;
                                    height: 5px;
                                }

                                &::-webkit-scrollbar-thumb {
                                    background: #0583F2;
                                    border-radius: 4px;
                                }

                                li {
                                    margin: 0;
                                    padding: 0;
                                    flex: 0 0 auto;

                                    figure {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        text-align: center;
                                        margin: 0;
                                        padding: 0;
                                        width: 91.5px;
                                        height: 80px;
                                        border: 1px solid #0000001f;
                                        justify-content: center;
                                        border-radius: 5px;
                                        box-shadow: 1px 1px 4px #adadad45;
                                        background: #d1d1d72e;

                                        .img-container {
                                            width: 40px;
                                            height: 40px;
                                            border-radius: 50%;

                                            img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                                border-radius: 50%;
                                            }
                                        }

                                        figcaption {
                                            padding-top: 6px;
                                            color: #000;
                                            font-size: 14px;
                                            overflow-wrap: break-word;
                                            word-wrap: break-word;
                                            word-break: break-word;
                                            white-space: normal;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 1;
                                            -webkit-box-orient: vertical;
                                        }
                                    }
                                }
                            }
                        }

                        //gird-view-results
                        .list-view-results {
                            cursor: pointer;
                            max-height: 290px;
                            overflow-y: auto;
                            overflow-x: hidden;
                            margin: 12px 0 0;
                            scrollbar-color: #0583F2 gray;
                            scrollbar-width: thin;

                            &::-webkit-scrollbar {
                                width: 5px;
                                background: #dcdcdc;
                                height: 100%;
                            }

                            &::-webkit-scrollbar-thumb {
                                background: #0583F2;
                                border-radius: 4px;
                            }

                            ul {
                                padding: 0;
                                margin: 0;
                                list-style: none;
                                display: flex;
                                flex-direction: column;
                                row-gap: 8px;

                                li {
                                    overflow: hidden;
                                    margin: 0;
                                    padding: 0;

                                    figure {
                                        display: flex;
                                        margin: 0;
                                        column-gap: 8px;
                                        border-radius: 5px;
                                        box-shadow: 1px 1px 4px #adadad45;
                                        background: #d1d1d72e;

                                        .img-container {
                                            width: 110px;
                                            height: 90px;
                                            flex: 0 0 auto;

                                            img {
                                                width: 100%;
                                                height: 100%;
                                                border-radius: 8px;
                                                object-fit: cover;
                                            }
                                        }

                                        figcaption {
                                            padding: 3px 0 0;

                                            h4 {
                                                margin: 0;
                                                color: #000;
                                                margin: 0;
                                                color: #000;
                                                font-size: 14px;
                                                font-weight: 400;
                                                overflow-wrap: break-word;
                                                word-wrap: break-word;
                                                word-break: break-word;
                                                white-space: normal;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 1;
                                                -webkit-box-orient: vertical;
                                            }

                                            h5 {
                                                margin: 0;
                                                color: #000;
                                                margin: 0;
                                                color: #000;
                                                font-size: 13px;
                                                font-weight: 400;
                                            }

                                            p {
                                                margin: 0;
                                                color: #333;
                                                font-size: 13px;
                                                font-weight: 400;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .search-results-dropdown-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    z-index: 1201;
                }
            }

            .hdrRigth_box {
                display: flex;
                align-items: center;
                column-gap: 17px;

                .x_credit_box {
                    span {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.15px;
                        color: #1C1B1F;
                        display: inline-block;
                        margin-right: 17px;
                    }
                }

                .toolbar-icon-btn,
                button {
                    padding: 0;
                    margin: 0;
                    min-width: unset;
                    position: relative;

                    img {}
                }

                .toolbar-user-info {
                    display: flex;
                    align-items: center;
                    column-gap: 8px;
                    margin: 0;
                    // max-width: 160px;
                    cursor: pointer;

                    @media (max-width: 575px) {
                        max-width: 80px;
                        column-gap: 5px;
                    }

                    .viewProf_img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        background-color: #F6F6F6;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        @media (max-width: 767px) {
                            width: 32px;
                            height: 32px;
                        }
                    }

                    .icon-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        width: 40px;
                        height: 40px;
                        letter-spacing: 0.15px;
                        color: #FFFBFE;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        background: #0583F2;
                        border-radius: 50%;
                        text-transform: uppercase;
                        flex: 0 0 auto;

                        @media (max-width: 480px) {
                            width: 30px;
                            height: 30px;
                            font-size: 15px;
                        }
                    }

                    figcaption {
                        flex: 1 1 auto;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.15px;
                        color: #1C1B1F;
                        overflow-wrap: break-word;
                        word-wrap: break-word;
                        word-break: break-word;
                        white-space: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;

                        @media (max-width: 480px) {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }

                .notification-dropdown {
                    cursor: auto;
                    text-align: left;
                    text-transform: capitalize;
                    display: none;
                    position: absolute;
                    color: rgba(0, 0, 0, 0.87);
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 456px;
                    width: 370px;
                    top: calc(100% + 17px);
                    padding: 0;
                    right: 0;
                    background: #FFFFFF;
                    border: 1px solid #D7D7D7;
                    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.2);
                    border-radius: 5px;

                    @media (max-width: 575px) {
                        position: fixed;
                        top: 62px;
                        left: 50%;
                        right: unset;
                        transform: translateX(-50%);
                        width: calc(100% - 10px);
                        max-width: 370px;
                    }

                    &.show {
                        display: block;
                        z-index: 901;
                    }

                    .noti-head-info {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 12px 10px;
                        column-gap: 8px;
                        border-bottom: 1px solid #D7D7D7;

                        @media (max-width: 575px) {
                            padding: 8px 8px;
                        }

                        .left-info {
                            display: flex;
                            align-items: center;
                            flex: 1 1 auto;
                            column-gap: 10px;

                            @media (max-width: 575px) {
                                column-gap: 6px;
                            }

                            h3 {
                                margin: 0;
                                padding: 0;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                letter-spacing: 0.5px;
                                color: #1C1B1F;

                                @media (max-width: 575px) {
                                    font-size: 14px;
                                    line-height: 22px;
                                }
                            }

                            small {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                letter-spacing: 0.25px;
                                color: #FFFFFF;
                                padding: 0 12px;
                                height: 28px;
                                background: #0583F2;
                                border-radius: 60px;
                                flex: 0 0 auto;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-align: center;

                                @media (max-width: 575px) {
                                    padding: 0 8px;
                                    height: 25px;
                                    font-size: 13px;
                                }
                            }
                        }

                        .right-info {
                            margin-left: auto;

                            .view-all-btn {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                letter-spacing: 0.5px;
                                color: #0583F2;
                                background: transparent;
                                border: 0;
                                outline: none;
                                text-decoration: none;
                                cursor: pointer;

                                @media (max-width: 575px) {
                                    font-size: 14px;
                                    line-height: 22px;
                                }
                            }
                        }
                    }

                    .notification-dropdown-lists {
                        .notification-dropdown-col {
                            &:not(:last-child) {
                                border-bottom: 1px solid #D7D7D7;
                            }

                            cursor: pointer;
                            padding: 12px 10px;

                            @media (max-width: 575px) {
                                padding: 10px 8px;
                            }

                            h4 {
                                margin: 0;
                                padding: 0;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                letter-spacing: 0.5px;
                                color: #1C1B1F;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;

                                @media (max-width: 575px) {
                                    font-size: 14px;
                                    line-height: 22px;
                                }
                            }

                            h5 {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 24px;
                                letter-spacing: 0.5px;
                                color: #49454F;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;

                                @media (max-width: 575px) {
                                    font-size: 14px;
                                    line-height: 22px;
                                }

                            }

                            h6 {
                                margin: 0;
                                padding: 0;
                                font-weight: 300;
                                font-size: 14px;
                                line-height: 24px;
                                letter-spacing: 0.5px;
                                color: #49454F;
                                display: flex;
                                align-items: flex-start;

                                @media (max-width: 575px) {
                                    font-size: 13px;
                                    line-height: 22px;
                                }

                                img {
                                    flex: 0 0 auto;
                                    width: 14px;
                                    margin: 4px 8px 0 0;

                                    @media (max-width: 575px) {
                                        width: 13px;
                                        margin: 4px 6px 0 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dropdown-menu-s1-menu-active-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 900;
    opacity: 0;
}

.dropdown-s1 {
    position: relative;

    .dropdown-menu-s1 {
        display: none;
        position: absolute;
        color: rgba(0, 0, 0, 0.87);
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 234px;
        width: 200px;
        top: calc(100% + 1px);
        padding: 5px 0;
        left: 0;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)),
            #FFFBFE;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
            0px 2px 6px 2px rgba(0, 0, 0, 0.15);
        border-radius: 4px;

        &.show {
            display: block;
            z-index: 901;
        }

        &.dropdown-menu-s1-menu {
            left: unset;
            right: 0;
        }

        .dropdown-item-s1 {
            display: block;
            text-decoration: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #1C1B1F;
            text-align: left;
            padding: 10px 14px;

            &:not(:last-child) {
                border-bottom: 1px solid #CAC4D0;
            }

            @media (max-width: 991px) {
                font-size: 14px;
                line-height: 22px;
            }

            &:hover {
                text-decoration: none;
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }
}

.MuiToolbar-regular {
    min-height: unset !important;
}

.dashboard-main-content {
    padding: 62px 0 0;
    width: 100%;
    max-width: 1650px;
    margin: 0 auto;
}

// end: dashbaord theme customize
// start: main-banner
.main-banner {
    overflow: hidden;
    margin-bottom: -5px;

    .main-banner-item {
        width: 100%;
        height: 540px;
        position: relative;
        background-color: #333;

        &::after {
            content: '';
            clear: both;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 90.62%);
            height: 50px;
            z-index: 1;
            width: 100%;
        }

        .bann-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .caption-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 2;
            height: calc(100% - 0px);
            padding: 40px 95px 0 38px;

            @media (max-width: 767px) {
                padding: 35px 10px 0 10px;
            }

            .top-actions {
                position: absolute;
                top: 20px;
                right: 20px;
                z-index: 2;
                display: flex;
                column-gap: 8px;

                @media (max-width: 767px) {
                    top: 8px;
                    right: 8px;
                }

                button {
                    min-width: unset;
                    padding: 0;
                    margin: 0;
                }

                .circle-btn {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #FFFFFF;
                    border-radius: 50%;
                    cursor: pointer;

                    @media (max-width: 767px) {
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            // background: linear-gradient(270deg, rgba(7, 18, 41, 0) 2.93%, #071229 57.02%);
            h2 {
                font-weight: 500;
                font-size: 28px;
                line-height: 34px;
                margin: 0;
                letter-spacing: 0.5px;
                color: #FFFFFF;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                width: 100%;
                max-width: 389px;

                @media (max-width: 575px) {
                    font-size: 24px;
                    line-height: 28px;
                }

            }

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 23px;
                letter-spacing: 0.5px;
                color: #FFFFFF;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                width: 100%;
                max-width: 558px;
                margin: 15px 0 0;

                @media (max-width: 575px) {
                    font-size: 14px;
                    margin: 12px 0 0;
                    -webkit-line-clamp: 5;
                    line-height: 21px;
                }
            }

            .more-btn {
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                width: 170px;
                height: 40px;
                background: #0583F2;
                border-radius: 60px;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: #FFFFFF;
                margin-top: 20px;
                transition: .5s;

                @media (max-width: 575px) {
                    width: 150px;
                    height: 36px;
                    margin-top: 15px;
                    font-size: 13px;
                }

                &:hover {
                    box-shadow: 1px 1px 14px grey;
                }
            }

        }

        .caption-healine {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 2;
            height: 36px;
            background: rgba(255, 255, 255, 0.8);
            display: flex;
            align-items: center;
            padding: 5px 30px;

            @media (max-width: 767px) {
                padding: 0 10px 0;
            }

            p {
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: #1E1E1E;

                @media (max-width: 991px) {
                    line-height: 20px;
                    font-size: 13px;
                }
            }
        }

        .bnrSlider_btm_cont {
            padding: 2px 38px 0;

            @media (max-width: 767px) {
                padding: 2px 10px 0;
            }

            h3 {
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: #1C1B1F;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            h5 {
                margin: 0;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.25px;
                color: #49454F;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }

    .slick-dots {
        bottom: 35%;
        visibility: hidden;
        display: flex !important;
        column-gap: 8px;
        justify-content: center;

        @media (max-width: 575px) {
            column-gap: 5px;
        }

        li {
            visibility: visible;
            margin: 0;
            height: unset;
            width: unset;

            button {
                width: 10px;
                height: 10px;
                background: rgba(31, 54, 97, 0.5);
                border: 2px solid #FFFFFF;
                border-radius: 10px;
                padding: 0;

                @media (max-width: 575px) {
                    width: 8px;
                    height: 8px;
                }

                &::before {
                    display: none !important;
                }

            }

            &.slick-active {
                button {
                    border: 2px solid #0583F2;
                    background: #0583F2;
                    border-radius: 10px;
                }
            }
        }
    }
}

// end: main-banner

.common-section-slider-heading-s1 {
    display: flex;
    column-gap: 20px;
    padding: 5px 38px;
    align-items: center;
    justify-content: space-between;
    background-color: #d7d2d2;
    min-height: 64px;
    border-bottom: 1px solid #BDBDBD;
    margin-bottom: 16px;
    margin-top: 25px;

    @media (max-width: 1449.98px) {
        min-height: 55px;
    }

    @media (max-width: 575px) {
        min-height: unset;
        padding: 8px 10px;
    }

    h3 {
        margin: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #080808;
        margin: 0;

        @media (max-width: 1449.98px) {
            font-size: 20px;
        }

        @media (max-width: 767px) {
            font-size: 16px;
        }

        @media (max-width: 575px) {
            font-size: 14px;
        }
    }
}

.common-section-slider-heading-s2 {
    display: flex;
    column-gap: 20px;
    padding: 0 38px;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    min-height: 64px;
    margin-bottom: 16px;

    &.bg1 {
        background: #04ACB8;
    }

    &.bg2 {
        background: #F83B3E;
    }

    &.bg3 {
        background: #FFA31C;
    }

    &.bg4 {
        background: #6CC149;
    }

    @media (max-width: 1449.98px) {
        min-height: 55px;
    }

    @media (max-width: 575px) {
        min-height: unset;
        padding: 8px 10px;
    }

    h3 {
        margin: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #fff;
        margin: 0;

        @media (max-width: 1449.98px) {
            font-size: 20px;
        }

        @media (max-width: 767px) {
            font-size: 16px;
        }

        @media (max-width: 575px) {
            font-size: 14px;
        }
    }

    .more-action {
        margin-left: auto;

        .more-btn {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.1px;
            text-decoration-line: underline;
            color: #FFFFFF;
            padding: 0;

            @media (max-width: 1449.98px) {
                font-size: 14px;
            }

            @media (max-width: 575px) {
                font-size: 12px;
            }
        }
    }
}

.common-section-slider {
    overflow: hidden;
    padding-left: 38px;
    padding-right: 38px;
    margin-bottom: 12px;

    @media (max-width: 575px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    .slick-next:before,
    .slick-prev:before {
        opacity: 1 !important;
        display: none !important;
    }

    .slick-next,
    .slick-prev {
        width: 38px;
        height: 38px;
        border: 0;
        background-color: #f5f5f5 !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
        z-index: 2;
        border-radius: 50%;
        background-size: 18px !important;

        @media (max-width: 575px) {
            width: 24px;
            height: 24px;
        }

        &.slick-disabled {
            opacity: 0.5;
        }
    }

    .slick-next {
        right: 0;
        background-image: url(../images/common-section-slider-right.png);
    }

    .slick-prev {
        left: 0;
        background-image: url(../images/common-section-slider-left.png);
    }

    .slick-list {
        margin: 0 -10px !important;
    }

    .slick-slide {
        padding: 0 10px !important;
    }

    .common-section-slider-item {
        .inner-box {
            border-radius: 12px;
            overflow: hidden;
            border: 1px solid #CAC4D0;
            background-color: #fff;
            cursor: pointer;

            .image-container {
                position: relative;
                height: 222px;

                @media (max-width: 900px) {
                    height: 25vw;
                }

                @media (max-width: 749px) {
                    height: 32vw;
                }

                @media (max-width: 499px) {
                    height: 50vw;
                }

                .btm-caption {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    z-index: 2;
                    padding: 6px 8px;
                    background: rgba(255, 255, 255, 0.8);

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                        color: #1E1E1E;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                        @media (max-width: 991px) {
                            line-height: 20px;
                            font-size: 13px;
                        }
                    }
                }

                .top-actions {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    z-index: 2;
                    display: flex;
                    column-gap: 8px;

                    @media (max-width: 767px) {
                        top: 8px;
                        right: 8px;
                    }

                    button {
                        min-width: unset;
                        padding: 0;
                        margin: 0;
                    }

                    .circle-btn {
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #FFFFFF;
                        border-radius: 50%;
                        cursor: pointer;

                        @media (max-width: 767px) {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                .box-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .text-container {
                padding: 15px 15px;

                @media (max-width: 991px) {
                    padding: 10px 10px;
                }

                h3 {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    color: #1C1B1F;
                    margin: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;

                    @media (max-width: 991px) {
                        font-size: 15px;
                    }
                }

                h5 {
                    margin: 0;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    color: #49454F;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;

                    @media (max-width: 991px) {
                        font-size: 13px;
                    }
                }

                p {
                    margin: 24px 0 0;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    color: #49454F;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                    @media (max-width: 991px) {
                        font-size: 13px;
                    }

                }
            }
        }
    }




    // start: no-slider-only-gird-view
    &.no-slider-only-gird-view {
        display: flex;
        flex-wrap: wrap;
        margin: -12px -10px;

        .common-section-slider-item {
            width: 100%;
            max-width: 33.33%;
            padding: 12px 10px;

            @media (max-width: 750px) {
                max-width: 50%;
            }

            @media (max-width: 500px) {
                max-width: unset;
            }
        }
    }

    // end: no-slider-only-gird-view
}

// start: dashboard details page
.dashboard-details-page-top-content {
    padding: 38px 38px;
    background-color: #fff;

    @media (max-width: 991px) {
        padding: 24px 24px 30px;
    }

    @media (max-width: 575px) {
        padding: 15px 15px 24px;
    }

    .main-banner {
        .main-banner-item {
            border: 1px solid #CAC4D0;
            border-radius: 12px;
            overflow: hidden;
        }
    }

    .details-page-paragraph-texts {
        padding: 28px 0 0;

        @media (max-width: 767px) {
            padding: 18px 0 0;
        }

        h2 {
            font-weight: 500;
            font-size: 28px;
            line-height: 31px;
            letter-spacing: 0.5px;
            color: #000000;
            margin: 0 0 5px;

            @media (max-width: 767px) {
                font-size: 22px;
                line-height: 25px;
            }
        }

        h4 {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #080808;
            margin: 0 0 20px;

            @media (max-width: 767px) {
                font-size: 16px;
                line-height: 22px;
                margin: 0 0 15px;
            }
        }

        p {
            font-weight: 300;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0.5px;
            margin: 0 0 24px;
            color: #000000;

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 21px;
                margin: 0 0 20px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .details-page-tag-list {
        display: flex;
        column-gap: 15px;
        margin: 30px 0 0;

        @media (max-width: 767px) {
            column-gap: 10px;
            margin: 20px 0 0;
        }

        p {
            font-weight: 300;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: 0.5px;
            color: #000000;
            margin: 4px 0 0;
            flex: 0 0 auto;

            @media (max-width: 767px) {
                font-size: 14px;
                margin: 2px 0 0;
                line-height: 20px;
            }
        }

        .lists {
            flex: 1 1 auto;
            display: flex;
            column-gap: 14px;
            padding: 0;
            flex-wrap: wrap;
            row-gap: 8px;

            @media (max-width: 767px) {
                column-gap: 8px;
            }

            .list-item {
                padding: 0;
                width: unset;

                .tag {
                    border: 1px solid #79747E;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    color: #49454F;
                    height: 31px;
                    padding: 0 10px;

                    @media (max-width: 767px) {
                        height: 28px;
                        padding: 0 8px;
                        font-size: 13px;
                        line-height: 18px;
                    }
                }
            }
        }
    }

    .custom-format-lists {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin: 30px 0 0;

        @media (max-width: 767px) {
            margin: 24px 0 0;
        }

        .lists {
            display: flex;
            background: #FFFBFE;
            border: 1px solid #CAC4D0;
            border-radius: 12px;
            padding: 0;

            @media (max-width: 575px) {
                flex-direction: column;
                padding: 5px 0;
            }

            .list-item {
                padding: 10px 16px;
                width: unset;
                display: block;

                @media (max-width: 767px) {
                    padding: 8px 13px;
                }

                @media (max-width: 575px) {
                    padding: 4px 15px;
                }

                h5 {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 23px;
                    letter-spacing: 0.5px;
                    color: #000000;
                    margin: 0;

                    @media (max-width: 575px) {
                        font-size: 13px;
                        line-height: 21px;
                    }
                }

                p {
                    margin: 0;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 23px;
                    letter-spacing: 0.5px;
                    color: #000000;

                    @media (max-width: 575px) {
                        font-size: 13px;
                        line-height: 21px;
                    }
                }
            }
        }
    }
}

.main-banner-bottom-informations {
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
    padding: 15px 0 0;

    @media (max-width: 575px) {
        column-gap: 10px;
    }

    .left-info {
        flex: 1 1 auto;

        figure {
            margin: 0;
            display: flex;
            align-items: center;
            column-gap: 18px;

            @media (max-width: 767px) {
                column-gap: 14px;
            }

            @media (max-width: 575px) {
                column-gap: 10px;
            }

            .icon-container {
                flex: 0 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                background: #EB2422;
                width: 59px;
                border-radius: 50%;
                height: 59px;

                @media (max-width: 575px) {
                    width: 50px;
                    height: 50px;
                }

                span {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                    color: #FFFBFE;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    background: #EB2422;
                    border: 1px solid #FFFFFF;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;

                    @media (max-width: 575px) {
                        width: 44px;
                        height: 44px;
                    }
                }

                img {
                    // font-weight: 500;
                    font-size: 12px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                    color: #FFFBFE;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    background: #EB2422;
                    border: 1px solid #FFFFFF;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;

                    @media (max-width: 575px) {
                        width: 44px;
                        height: 44px;
                    }
                }
            }

            figcaption {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: #1C1B1F;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }

    .right-info {
        .lists {
            display: flex;
            column-gap: 12px;
            row-gap: 8px;
            padding: 10px 0 0;

            @media (max-width: 1400px) {
                column-gap: 8px;
            }

            .list-item {
                padding: 0;
                width: unset;

                .icon-with-counter {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 87px;
                    height: 40px;
                    border-radius: 60px;
                    background: rgba(5, 131, 242, 0.05);
                    border: 1px solid #0583F2;
                    column-gap: 10px;

                    @media (max-width: 1400px) {
                        height: 33px;
                        column-gap: 5px;
                        width: 70px;
                    }

                    @media (max-width: 575px) {
                        height: 30px;
                        column-gap: 3px;
                        width: 55px;
                    }

                    img {
                        max-width: 20px;

                        @media (max-width: 1400px) {
                            max-width: 15px;
                        }

                        @media (max-width: 575px) {
                            max-width: 13px;
                        }
                    }

                    span {
                        font-weight: 500;
                        letter-spacing: 0.1px;
                        font-size: 14px;
                        line-height: 20px;
                        color: #0583F2;

                        @media (max-width: 1400px) {
                            font-size: 13px;
                            line-height: 18px;
                        }

                        @media (max-width: 575px) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

.selected-tags {
    padding: 26px 0 0;

    @media (max-width: 767px) {
        padding: 15px 0 0;
    }

    .lists {
        display: flex;
        flex-wrap: wrap;
        column-gap: 12px;
        row-gap: 8px;

        @media (max-width: 767px) {
            column-gap: 8px;
        }

        .list-item {
            padding: 0;
            width: unset;

            .tag {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0px 24px;
                height: 40px;
                background: rgba(28, 27, 31, 0.12);
                text-align: center;
                letter-spacing: 0.1px;
                color: #1C1B1F;
                opacity: 0.38;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                border-radius: 60px;

                @media (max-width: 767px) {
                    font-size: 13px;
                    padding: 0px 16px;
                    height: 32px;
                }

                &.active {
                    background: #0583F2;
                    color: #fff;
                    opacity: 1;
                }

                &.calenderIcon {
                    min-width: auto !important;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    padding: 8px;

                    img {
                        filter: brightness(0) invert(1);
                        width: 20px;
                    }
                }

                &.attendence {
                    width: 230px;

                    @media (max-width: 767px) {
                        width: auto;
                    }
                }
            }
        }
    }
}

// end: dashboard details page

// start: account-banner
.account-banner {
    width: 100%;
    position: relative;
    background-color: #0583F2;
    display: flex;
    flex-direction: column;
    margin-bottom: 63px;
    height: 300px;

    @media (max-width: 1024px) {
        height: 250px;
    }

    @media (max-width: 767px) {
        height: 180px;
        margin-bottom: 35px;
    }

    @media (max-width: 575px) {
        margin-bottom: 10px;
    }

    .bg-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .action-btn {
        position: absolute;
        bottom: 38px;
        right: 38px;
        display: flex;
        z-index: 1;

        @media (max-width: 767px) {
            bottom: 15px;
            right: 15px;
        }

        .change-btn {
            width: 137px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FFFFFF;
            border-radius: 60px;
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #0583F2;
            cursor: pointer;
            text-decoration: none;
            transition: .4s;
            overflow: hidden;

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }

            @media (max-width: 767px) {
                width: 124px;
                height: 35px;
                font-size: 13px;
            }

            &:hover {
                box-shadow: 1px 2px 7px grey;
            }
        }
    }

    .user-pic {
        position: absolute;
        bottom: -80px;
        left: calc(50% - 0px);
        transform: translateX(-50%);
        z-index: 2;
        margin: 0;

        @media (max-width: 767px) {
            bottom: -60px;
        }

        @media (max-width: 575px) {
            left: 15px;
            transform: translate(0);
            bottom: -24px;
        }


        .img-container {
            width: 200px;
            height: 200px;
            border: 3px solid #FFFFFF;
            border-radius: 50%;

            @media (max-width: 767px) {
                width: 120px;
                height: 120px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        figcaption {
            width: 50px;
            height: 50px;
            background: #0583F2;
            border: 3px solid #FFFFFF;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            right: 0;
            cursor: pointer;

            @media (max-width: 767px) {
                width: 32px;
                height: 32px;
            }

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }

            img {
                width: 24px;
                object-fit: contain;

                @media (max-width: 767px) {
                    width: 14px;
                }
            }
        }
    }
}

// end: account-banner
.dashboard-main-content.bg-white {
    min-height: 100vh;
}

.bg-white {
    background-color: #fff;
}

// start: account-main-form-contents
.account-main-form-contents {
    width: 100%;
    max-width: calc(768px + 30px);
    margin: 0 auto 0;
    padding: 52px 15px 50px;

    @media (max-width: 767px) {
        padding: 44px 10px 30px;
    }

    @media (max-width: 575px) {
        padding: 32px 10px 30px;
    }

    .form-group-heading {
        display: flex;
        align-items: center;
        padding: 12px;
        height: 48px;
        background: #0583F2;
        justify-content: space-between;
        column-gap: 15px;

        @media (max-width: 575px) {
            padding: 8px;
            column-gap: 10px;
            height: 42px;
        }

        h3 {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: #FFFFFF;
            margin: 0;
            padding: 0;

            @media (max-width: 575px) {
                font-size: 15px;
                line-height: 20px;
            }
        }
    }

    .form-group-body {
        padding: 32px 0 42px;

        @media (max-width: 575px) {
            padding: 20px 0 24px;
        }

        .left-text-with-rg-btn {
            display: flex;
            align-items: center;
            column-gap: 15px;
            justify-content: space-between;

            @media (max-width: 575px) {
                column-gap: 10px;
            }

            h4 {
                padding: 0;
                margin: 0;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.5px;
                color: #080808;
                display: flex;
                flex-wrap: wrap;
                // max-width: 200px !important;

                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    background: #f0f8ff;
                    color: #0583F2;
                    border: 1px solid #0583F2;
                    border-radius: 60px;
                    font-weight: 500;
                    font-size: 12px;
                    height: 34px;
                    line-height: 17px;
                    padding: 0px 15px;
                    white-space: nowrap;
                    margin: 2px;
                }

                @media (max-width: 575px) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .left-flow-lists {
                display: flex;
                flex: 1 1 auto;
                column-gap: 5px;
                row-gap: 5px;
            }

            .rg-action {
                flex: 0 0 auto;
                margin-left: auto;

                .chang-btn {
                    width: 97px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid #0583F2;
                    border-radius: 100px;
                    text-align: center;
                    background-color: #fff;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1px;
                    color: #0583F2;
                    cursor: pointer;
                    transition: .4s;

                    @media (max-width: 575px) {
                        font-size: 13px;
                        width: 90px;
                        height: 36px;
                    }

                    &:hover {
                        box-shadow: 1px 2px 7px grey;
                    }
                }
            }
        }

        .form-group-s1 {
            margin-bottom: 35px;

            @media (max-width: 575px) {
                margin-bottom: 24px;
            }
        }

        .custom-row .custom-col.col6 {
            @media (max-width: 575px) {
                max-width: unset;
            }

        }
    }

    .form-group-actions {
        display: flex;
        justify-content: center;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        padding: 26px 0 0;

        .submit-btn {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            letter-spacing: 0.1px;
            color: #FFFFFF;
            width: 222px;
            height: 40px;
            background: #0583F2;
            border-radius: 60px;

            @media (max-width: 575px) {
                font-size: 13px;
                width: 180px;
                height: 36px;
            }
        }
    }
}

.mb-0 {
    margin-bottom: 0 !important;
}

.custom-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;

    .custom-col {
        width: 100%;
        padding: 0 8px;

        &.col6 {
            max-width: 50%;
        }

        &.col3 {
            max-width: 33.33%;
        }
    }
}

// end: account-main-form-contents

// start: custom-change-modal
.custom-change-modal {
    .dialog-header {
        display: flex;
        justify-content: space-between;
        column-gap: 15px;
        padding: 12px 18px;

        h2 {
            margin: 0;
            padding: 0;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #1C1B1F;
        }

        .close-btn {
            margin-left: auto;
            margin: 0;
            padding: 0;
        }
    }

    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        width: 100%;
        max-width: 532px;
    }

    .dialog-body {
        .dialog-body-heading {
            background: #e4e4e4;
            font-size: 16px;
            color: #000;
            padding: 7px 10px;
            border-radius: 5px;
            line-height: 22px;
        }

        .following-lists {
            margin: 20px 0 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
            row-gap: 20px;

            .following-list-item {
                margin: 0;
                padding: 0;
                flex: 1 1 auto;

                .following-box {
                    display: flex;
                    column-gap: 28px;
                    align-items: center;

                    .left-info {
                        flex: 1 1 auto;
                        overflow-wrap: break-word;
                        word-wrap: break-word;
                        word-break: break-word;
                        white-space: normal;

                        h3 {
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 25px;
                            color: #000;
                            margin: 0;
                            padding: 0;
                        }

                        p {
                            font-size: 14px;
                            line-height: 21px;
                            color: #333;
                            font-weight: 400;
                            margin: 5px 0 0;
                            padding: 0;
                        }
                    }

                    .right-action {
                        flex: 0 0 auto;
                        margin-left: auto;

                        .follow-btn {
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            letter-spacing: 0.1px;
                            color: #FFFFFF;
                            width: 85px;
                            height: 34px;
                            background: #0583F2;
                            border-radius: 60px;
                            border: 0;
                            transition: .3s;

                            &:hover {
                                box-shadow: 1px 2px 7px grey;
                            }

                            @media (max-width: 575px) {
                                font-size: 13px;
                                width: 180px;
                                height: 36px;
                            }
                        }

                        .unfollow-btn {
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            letter-spacing: 0.1px;
                            color: #0583F2;
                            width: 95px;
                            height: 34px;
                            background: #fff;
                            border-radius: 60px;
                            border: 1px solid #0583F2;
                            transition: .3s;

                            &:hover {
                                box-shadow: 1px 2px 7px grey;
                            }

                            @media (max-width: 575px) {
                                font-size: 13px;
                                width: 180px;
                                height: 36px;
                            }
                        }
                    }
                }
            }
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: center;
        padding: 16px 18px;

        .submit-btn {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            letter-spacing: 0.1px;
            color: #FFFFFF;
            width: 222px;
            height: 40px;
            background: #0583F2;
            border-radius: 60px;


        }
    }
}

// end: custom-change-modal


// start: dashboard-notification-page

.dashboard-notification-page {
    padding: 28px 32px 50px;
    background: #fff;
    min-height: calc(100vh - 64px);

    @media (max-width: 1024px) {
        padding: 24px 24px 32px;
    }

    @media (max-width: 575px) {
        padding: 16px 16px 24px;
    }

    .mw-ass-par-container {
        width: 100%;
        max-width: 1160px;
        margin: 0 auto;
    }

    .common-section-slider.no-slider-only-gird-view {
        padding: 0;
        margin-top: 28px;

        @media (max-width: 1560px) {
            margin-top: 24px;
        }

        @media (max-width: 1024px) {
            margin-top: 16px;
        }

        @media (max-width: 640px) {
            margin-top: 10px;

        }

        @media (max-width: 575px) {
            margin-top: 8px;

        }
    }

}

.left-tab-w-right-actions {
    display: flex;
    justify-content: space-between;
    column-gap: 15px;

    @media (max-width: 1024px) {
        column-gap: 10px;
    }

    @media (max-width: 575px) {
        column-gap: 6px;
    }

    .left-tab {
        flex: 1 1 auto;
    }

    .right-actions {
        flex: 0 0 auto;
        margin-left: auto;

        .add-btn {
            width: 274px;
            height: 48px;
            background: #0583F2;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: #FFFFFF;

            @media (max-width: 1560px) {
                width: 220px;
                height: 45px;
                font-size: 16px;
            }

            @media (max-width: 1024px) {
                width: 160px;
                height: 42px;
                font-size: 15px;
            }

            @media (max-width: 640px) {
                width: 132px;
                height: 38px;
                font-size: 14px;
            }

            @media (max-width: 575px) {
                width: unset;
                height: 36px;
                font-size: 13px;
                padding: 0 16px;
            }
        }
    }
}

.common-tab-s1 {
    ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;

        @media (max-width: 1560px) {
            column-gap: 15px;
            row-gap: 15px;
        }

        @media (max-width: 1024px) {
            column-gap: 10px;
            row-gap: 10px;
        }

        li {
            padding: 0;
            margin: 0;
            width: unset;

            .common-tab-s1-btn {
                width: 274px;
                height: 48px;
                background: #E8EBEC;
                padding: 0 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                cursor: pointer;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: #080808;

                @media (max-width: 1560px) {
                    width: 220px;
                    height: 45px;
                    font-size: 16px;
                }

                @media (max-width: 1024px) {
                    width: 160px;
                    height: 42px;
                    font-size: 15px;
                }

                @media (max-width: 640px) {
                    width: 132px;
                    height: 38px;
                    font-size: 14px;
                }

                @media (max-width: 575px) {
                    font-size: 13px;
                    width: unset;
                    height: 36px;
                    padding: 0 16px;
                }

                &.active {
                    background: #0583F2;
                    color: #fff;
                }
            }
        }
    }
}

// end: dashboard-notification-page

// start: basicDetails-page
.basicDetails-page {
    padding: 55px 32px 50px;
    background: #fff;
    min-height: calc(100vh - 64px);
    display: flex;
    column-gap: 24px;

    @media (max-width: 1250px) {
        flex-wrap: wrap;
        row-gap: 30px;
    }

    @media (max-width: 1024px) {
        padding: 32px 24px 32px;
    }

    @media (max-width: 575px) {
        padding: 16px 16px 24px;
    }

    .left-section {
        width: 100%;
        max-width: 65%;

        @media (max-width: 1250px) {
            max-width: unset;
        }

        .common-tab-s1 {
            margin-bottom: 45px;

            @media (max-width: 1024px) {
                margin-bottom: 28px;
            }

            @media (max-width: 575px) {
                margin-bottom: 16px;
            }

            ul {
                column-gap: unset !important;
                row-gap: unset !important;
                margin: -8px;

                @media (max-width: 475px) {
                    margin: -4px;
                }

                li {
                    width: 100%;
                    max-width: 25%;
                    padding: 8px;

                    @media (max-width: 600px) {
                        max-width: 33.33%;
                    }

                    @media (max-width: 475px) {
                        max-width: 50%;
                        padding: 4px;

                    }

                    .common-tab-s1-btn {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    .right-section {
        width: 100%;
        max-width: 35%;

        @media (max-width: 1250px) {
            max-width: unset;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #1C1B1F;
            margin: 0;

            @media (max-width: 575px) {
                font-size: 14px;
            }
        }

        .para-divider {
            height: 32px;

            @media (max-width: 575px) {
                height: 24px;
            }
        }
    }

    .basic-details-tab-contents {
        .basic-details-tab-submit-actions {
            border-top: 1px solid rgba(0, 0, 0, 0.12);
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 16px;
            padding: 16px 0 0;
            margin: 42px 0 0;

            .submit-btn,
            .back-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                width: 222px;
                height: 40px;
                border-radius: 60px;
            }

            .submit-btn {
                background: #0583F2;
                color: #fff;
            }

            .back-btn {
                background: #E8EBEC;
                color: #0583F2;
            }
        }

        .basic-details-tab-form-lists {
            .image-list-with-title {
                p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 23px;
                    letter-spacing: 0.5px;
                    color: #1C1B1F;
                    margin-bottom: 24px;

                    @media (max-width: 575px) {
                        font-size: 14px;
                        margin-bottom: 16px;
                    }
                }
            }

            .select-image-list-girds {
                display: flex;
                flex-wrap: wrap;
                margin: -7.5px;

                .select-image-list-gird-col {
                    padding: 7.5px;
                    width: 100%;
                    max-width: 25%;

                    @media (max-width: 800px) {
                        max-width: 33.33%;
                    }

                    @media (max-width: 600px) {
                        max-width: 50%;
                    }

                    figure {
                        background: #FFFBFE;
                        border: 1px solid #CAC4D0;
                        border-radius: 12px;
                        height: 172px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 !important;
                        padding: 0 !important;
                        position: relative;
                        overflow: hidden;

                        .upload-icon {
                            width: 31px;
                        }

                        img {
                            &:not(.upload-icon) {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        input[type=file] {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}

.form-group-s1-slider-with-label-control {
    background-color: #0000000f;
    border-radius: 4px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 12px 16px;
    display: flex;
    flex-direction: column;

    .left-slider-progess-with-right-no-value {
        display: flex;
        justify-content: space-between;
        column-gap: 32px;
        align-items: center;

        .left-slider-progess {
            flex: 1 1 auto;
        }

        .right-no {
            flex: 0 0 auto;
            margin-left: auto;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #1C1B1F;
        }
    }

    label {
        margin: 0 0 8px;
        font-size: 13px;
        color: #0583F2;
    }

    .ProgessSlider {
        padding: 0 !important;
        height: 9.5px !important;
        border-radius: 0 !important;

        .css-14pt78w-MuiSlider-rail {
            background-color: #1E1E1E !important;
            opacity: 1 !important;
        }

        .css-1i2q1v3-MuiSlider-thumb {
            background: #FFFFFF !important;
            border: 1px solid #000000 !important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
        }
    }
}

.form-group-left-text-with-right-toggle-btn {
    display: flex;
    justify-content: space-between;
    background-color: #0000000f;
    border-radius: 4px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 12px 16px;
    column-gap: 16px;

    .left-text {
        flex: 1 1 auto;

        h4 {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #1C1B1F;
            margin: 0 !important;
            padding: 0 !important;
        }

        h5 {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: #1C1B1F;
            margin: 0 !important;
            padding: 0 !important;
        }
    }

    .right-toggle {
        flex: 0 0 auto;
    }
}

/* myAccount_wrapper */
.myAccount_wrapper {
    max-width: calc(768px + 30px);
    margin: 0px auto;
    padding: 32px 15px 30px;

    h3 {
        font-weight: 400;
        font-size: 32px;
        line-height: 23px;
        letter-spacing: 0.5px;
        color: #1C1B1F;
        text-align: center;
        margin-bottom: 24px;

        @media only screen and (max-width: 680px) {
            font-size: 26px;
        }

        @media only screen and (max-width: 575px) {
            font-size: 22px;
        }
    }

    .social_description {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0px 12px 12px;
        border-bottom: 1px solid rgba(73, 69, 79, 0.3);

        .social_descBox {
            display: flex;
            align-items: center;

            @media only screen and (max-width: 575px) {
                width: 50%;
                margin-bottom: 5px;
            }

            @media only screen and (max-width: 390px) {
                width: 100%;
            }

            figure {
                width: 32px;
                height: 32px;
                margin: 0px;

                @media only screen and (max-width: 680px) {
                    width: 22px;
                    height: 22px;
                }

                img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .desc_cont {
                width: calc(100% - 32px);
                padding-left: 12px;

                @media only screen and (max-width: 680px) {
                    width: calc(100% - 22px);
                }

                .label_mark {
                    display: block;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 23px;
                    letter-spacing: 0.5px;
                    color: #0583F2;
                }

                .descContent {
                    display: block;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 23px;
                    letter-spacing: 0.5px;
                    color: #1C1B1F;
                    margin: 0px;

                    @media only screen and (max-width: 680px) {
                        font-size: 14px;
                        line-height: 22px;
                    }

                }

                a {
                    text-decoration: none;
                }
            }
        }
    }
}

.myAct_tabArea {
    margin-top: 8px;
    padding: 12px 12px 20px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(73, 69, 79, 0.3);

    .myAct_tabBtn {
        border: 1px solid #0583F2;
        border-radius: 100px;
        padding: 9px 0px;
        width: 222px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: #0583F2;

        @media only screen and (max-width: 767px) {
            width: 175px;
        }

        @media only screen and (max-width: 480px) {
            width: 125px;
        }

        &:hover,
        &.activeTab_btn {
            background: #0583F2;
            color: #FFFFFF;
        }

        &+.myAct_tabBtn {
            margin-left: 8px;
        }
    }
}

.tabCont_wrapper {
    .tabCont_box {
        text-align: center;
        padding: 32px 0px;

        p {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: #4F4F4F;
            margin: 10px 0px 0px;
        }
    }
}

.addBtn {
    position: fixed;
    right: 76px;
    bottom: 76px;
    width: 76px;
    height: 76px;
    background: linear-gradient(0deg, rgba(5, 131, 242, 0.05), rgba(5, 131, 242, 0.05)), #0583F2;
    box-shadow: 0px 4px 8px 3px rgba(5, 131, 242, 0.15), 0px 1px 3px rgba(5, 131, 242, 0.3);
    border-radius: 58px;
    border: none;
    cursor: pointer;

    img {
        filter: brightness(0) invert(1);

        @media only screen and (max-width: 767px) {
            width: 16px;
        }
    }


    @media only screen and (max-width: 767px) {
        width: 48px;
        height: 48px;
        right: 20px;
        bottom: 20px;
    }
}

/* 13-02-2023 - by Rakesh */
.attendingCont_wrapper {
    padding-top: 30px;
    max-width: 900px;
    overflow: auto;

    .common-section-slider-item {
        width: 100%;

        .inner-box {
            border-radius: 12px;
            overflow: hidden;
            border: 1px solid #CAC4D0;
            background-color: #fff;
            cursor: pointer;
            display: flex;
            flex-wrap: wrap;

            .image-container {
                position: relative;
                width: 35%;
                height: 222px;

                @media (max-width: 900px) {
                    height: 22vw;
                }

                @media (max-width: 749px) {
                    height: 30vw;
                    width: 45%;
                }

                @media (max-width: 480px) {
                    height: 40vw;
                    width: 100%;
                }

                .btm-caption {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    z-index: 2;
                    padding: 6px 8px;
                    background: rgba(255, 255, 255, 0.8);

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 24px;
                        letter-spacing: 0.5px;
                        color: #1E1E1E;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                        @media (max-width: 991px) {
                            line-height: 20px;
                            font-size: 13px;
                        }
                    }
                }

                .top-actions {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    z-index: 2;
                    display: flex;
                    column-gap: 8px;

                    @media (max-width: 767px) {
                        top: 8px;
                        right: 8px;
                    }

                    button {
                        min-width: unset;
                        padding: 0;
                        margin: 0;
                    }

                    .circle-btn {
                        width: 30px;
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #FFFFFF;
                        border-radius: 50%;
                        cursor: pointer;

                        @media (max-width: 767px) {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }

                .box-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .text-container {
                width: 65%;
                padding: 15px 15px;
                text-align: left;

                @media (max-width: 991px) {
                    padding: 10px 10px;
                }

                @media (max-width: 749px) {
                    width: 55%;
                }

                @media (max-width: 480px) {
                    width: 100%;
                }

                h3 {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.5px;
                    color: #1C1B1F;
                    margin: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    text-align: left;

                    @media (max-width: 991px) {
                        font-size: 15px;
                    }
                }

                h5 {
                    margin: 0;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    color: #49454F;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;

                    @media (max-width: 991px) {
                        font-size: 13px;
                    }
                }

                p {
                    margin: 24px 0 0;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    color: #49454F;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                    @media (max-width: 991px) {
                        font-size: 13px;
                    }

                }
            }
        }
    }
}

// end: basicDetails-page
.mw-100 {
    max-width: 100% !important;
}

.basic-details-tab-submit-actions {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    padding: 16px 0 0;
    margin: 42px 0 0;

    .submit-btn,
    .back-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 222px;
        height: 40px;
        border-radius: 60px;
    }

    .submit-btn {
        background: #0583F2;
        color: #fff;
    }

    .back-btn {
        background: #E8EBEC;
        color: #0583F2;
    }
}


.view_user_profBox {
    margin-top: 15px;

    @media (max-width: 767px) {
        margin-top: 10px;
    }

    .view_user_prof {
        margin: 0px;
        display: flex;
        align-items: center;

        .viewProf_img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #F6F6F6;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media (max-width: 767px) {
                width: 32px;
                height: 32px;
            }
        }

        .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 40px;
            height: 40px;
            letter-spacing: 0.15px;
            color: #FFFBFE;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            background: #0583F2;
            border-radius: 50%;
            text-transform: uppercase;
            flex: 0 0 auto;

            @media (max-width: 480px) {
                width: 30px;
                height: 30px;
                font-size: 15px;
            }
        }

        figcaption {
            width: calc(100% - 40px);
            padding-left: 12px;
            font-size: 15px;
            font-weight: 600;
            line-height: 22px;
            color: #000000;

            @media (max-width: 767px) {
                width: calc(100% - 32px);
                font-size: 13px;
                line-height: 18px;
            }
        }
    }
}

.react-datepicker-popper {
    z-index: 2 !important;
}

// start: video-call-sec
.video-call-sec {
    width: calc(100% - 16px);
    max-width: 991px;
    margin: 8px auto 16px;
    background-color: #fff;
    padding: 0 0 10px;

    @media (max-width: 1024px) {
        margin: 16px auto 0;
    }

    .video-frame {
        width: 100%;
        height: 30vw;
        min-height: 320px;

        @media (max-width: 1024px) {
            min-height: 290px;
        }

        video,
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: #1b1b1b;
        }
    }

    .join-conformation-box {
        padding: 10px;
        border-radius: 10px;
        margin-top: 24px;

        h3 {
            margin: 0;
            padding: 0;
            font-size: 24px;
            font-weight: 600;
            color: #000;
            line-height: 30px;
        }

        p {
            margin: 4px 0 0;
            padding: 0;
            font-size: 16px;
            font-weight: 400;
            color: #333;
            line-height: 25px;
        }

        .action-togglers {
            width: 100%;
            max-width: 420px;
            margin: 16px auto 0;

            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 15px;
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    width: unset;
                    margin: 0;
                    padding: 0;
                }
            }
        }

        .action-buttons {
            width: 100%;
            max-width: 420px;
            margin: 16px auto 0;

            ul {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    width: unset;
                    margin: 0;
                    padding: 0;

                    button {
                        width: 100%;
                    }
                }
            }
        }
    }

    .after-join-box {
        padding: 10px;
        border-radius: 10px;
        margin-top: 24px;

        .join-user-lists {
            display: flex;
            flex-wrap: wrap;
            row-gap: 10px;
            align-items: center;
            column-gap: 10px;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                width: unset;
                margin: 0;
                padding: 0;
            }
        }

        h4 {
            display: flex;
            align-items: center;
            column-gap: 8px;

            .icon-container {
                flex: 0 0 auto;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: #fff;
                background-color: purple;
                font-size: 15px;
                text-transform: uppercase;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            span {
                font-size: 14px;
                color: #000;
                font-weight: 400;
                margin: 0;
                padding: 0;
            }
        }

        .text-with-buttons-info-after-join {
            background-color: #d1d1d1;
            padding: 10px;
            width: 100%;
            max-width: 420px;
            margin: 16px auto 0;
            padding: 10px 24px 15px;
            border-radius: 10px;
            h5 {
                margin: 0;
                padding: 0;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
                color: #000;
                line-height: 24px;
            }

            .text-link-buttons {
                margin: 10px 0 0;
                padding: 0;
                list-style: none;
                display: flex;
                justify-content:center;
                column-gap: 24px;

                li {
                    width: unset;
                    margin: 0;
                    padding: 0;

                    .text-btn {
                        padding: 0;
                        margin: 0;
                        font-size: 14px;
                        text-transform: capitalize;
                        font-size: 14px;
                    }
                }
            }
            .more-actions-button{
                margin: 10px 0 0;
                padding: 0;
                list-style: none;
                display: flex;
                justify-content:center;
                column-gap: 16px;
                li{
                    padding: 0;
                    width: unset;
                }
                .theme-solid-btn{
                    font-size: 20px;
                    img{
                        width: 18px;
                    }
                    &.off{
                        background-color: #ff5c9d;
                        border-color: #ff5c9d;
                    }
                }
            }
        }
        
    }
}

body .theme-solid-btn,
body .theme-border-btn {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 16px;
    font-weight: 500;
    border-radius: 10px;
    border: 1px solid #0583F2;
}

body .theme-solid-btn {
    background-color: #0583F2;
    color: #fff;

    &:hover,
    &:focus,
    &.active {
        background-color: #0583F2;
        color: #fff;
    }
}

body .theme-border-btn {
    background-color: #fff;
    color: #0583F2;

    &:hover,
    &:focus,
    &.active {
        background-color: #fff;
        color: #0583F2;
    }
}

.custom-tick-switcher {}

.fa {
    font: normal normal normal 14px/1 FontAwesome !important;
}

// end: video-call-sec